import React, { useEffect, useRef } from 'react';
import './styles/hero.css';

const AnimatedCircle = () => {
  const canvasRef = useRef(null);
  const dotsRef = useRef([]);
  const animationRef = useRef({
    angle: 0,
    speed: 0,
    targetSpeed: 0,
    direction: 1,
    timeoutId: null,
    state: 'idle',
    stateTimeout: null,
    activeConnections: []
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const circleRadius = 150;
    const numDots = 12;
    let animationFrame;

    // Initialize dots with communication properties
    for (let i = 0; i < numDots; i++) {
      const angle = (i / numDots) * Math.PI * 2;
      dotsRef.current.push({
        angle,
        radius: circleRadius + (Math.random() - 0.5) * 10,
        size: 4 + (Math.random() - 0.5) * 2,
        pulseOffset: Math.random() * Math.PI * 2,
        opacity: 1,
        energy: 0,
        communicating: false,
        communicationPartner: null
      });
    }

    const easeInOutCubic = t => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;

    const changeState = () => {
      const states = ['idle', 'spinning', 'slowing'];
      const currentState = animationRef.current.state;
      const nextState = states[(states.indexOf(currentState) + 1) % states.length];
      
      animationRef.current.state = nextState;
      
      switch (nextState) {
        case 'idle':
          animationRef.current.targetSpeed = 0;
          animationRef.current.stateTimeout = setTimeout(changeState, Math.random() * 2000 + 2000);
          break;
        case 'spinning':
          animationRef.current.direction = Math.random() > 0.5 ? 1 : -1;
          animationRef.current.targetSpeed = (Math.random() * 0.02 + 0.02) * animationRef.current.direction;
          animationRef.current.stateTimeout = setTimeout(changeState, Math.random() * 3000 + 2000);
          break;
        case 'slowing':
          animationRef.current.targetSpeed = 0.001 * animationRef.current.direction;
          animationRef.current.stateTimeout = setTimeout(changeState, Math.random() * 1000 + 1000);
          break;
      }
    };

    // Add new function to handle dot communications
    const updateCommunications = () => {
      if (Math.random() > 0.97) {
        const availableDots = dotsRef.current.filter(dot => !dot.communicating);
        if (availableDots.length >= 2) {
          const dot1Index = Math.floor(Math.random() * availableDots.length);
          let dot2Index;
          do {
            dot2Index = Math.floor(Math.random() * availableDots.length);
          } while (dot2Index === dot1Index);

          const dot1 = availableDots[dot1Index];
          const dot2 = availableDots[dot2Index];
          
          dot1.communicating = true;
          dot2.communicating = true;
          dot1.communicationPartner = dot2;
          dot2.communicationPartner = dot1;
          dot1.energy = 1;
          dot2.energy = 1;

          animationRef.current.activeConnections.push({
            dot1,
            dot2,
            progress: 0,
            duration: Math.random() * 1000 + 500
          });

          // Schedule end of communication
          setTimeout(() => {
            dot1.communicating = false;
            dot2.communicating = false;
            dot1.communicationPartner = null;
            dot2.communicationPartner = null;
            dot1.energy = 0;
            dot2.energy = 0;
            animationRef.current.activeConnections = animationRef.current.activeConnections
              .filter(conn => conn.dot1 !== dot1);
          }, Math.random() * 1000 + 500);
        }
      }
    };

    // Update the draw function to include communication effects
    const drawCommunication = (dot1, dot2, progress) => {
      const x1 = canvas.width/2 + Math.cos(dot1.angle + animationRef.current.angle) * dot1.radius;
      const y1 = canvas.height/2 + Math.sin(dot1.angle + animationRef.current.angle) * dot1.radius;
      const x2 = canvas.width/2 + Math.cos(dot2.angle + animationRef.current.angle) * dot2.radius;
      const y2 = canvas.height/2 + Math.sin(dot2.angle + animationRef.current.angle) * dot2.radius;

      // Create lightning effect
      const segments = 8; // Number of segments in the lightning
      const points = [{ x: x1, y: y1 }];
      
      // Generate lightning points with controlled randomness
      for (let i = 1; i < segments; i++) {
        const t = i / segments;
        const baseX = x1 + (x2 - x1) * t;
        const baseY = y1 + (y2 - y1) * t;
        
        // Add controlled randomness
        const perpX = -(y2 - y1);
        const perpY = (x2 - x1);
        const perpLength = Math.sqrt(perpX * perpX + perpY * perpY);
        
        // Randomness decreases as we get closer to the target
        const offset = (Math.random() - 0.5) * 20 * (1 - t);
        points.push({
          x: baseX + (perpX / perpLength) * offset,
          y: baseY + (perpY / perpLength) * offset
        });
      }
      points.push({ x: x2, y: y2 });

      // Draw the main lightning path
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);
      points.forEach((point, i) => {
        if (i > 0) {
          ctx.lineTo(point.x, point.y);
        }
      });
      
      // Create gradient for the lightning
      const gradient = ctx.createLinearGradient(x1, y1, x2, y2);
      gradient.addColorStop(0, `rgba(100, 255, 218, ${0.8 * (1 - progress)})`);
      gradient.addColorStop(0.5, `rgba(150, 255, 230, ${0.9 * (1 - progress)})`);
      gradient.addColorStop(1, `rgba(100, 255, 218, ${0.8 * (1 - progress)})`);
      
      ctx.strokeStyle = gradient;
      ctx.lineWidth = 2;
      ctx.stroke();

      // Draw glow effect
      ctx.save();
      ctx.filter = 'blur(4px)';
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);
      points.forEach((point, i) => {
        if (i > 0) {
          ctx.lineTo(point.x, point.y);
        }
      });
      ctx.strokeStyle = `rgba(100, 255, 218, ${0.3 * (1 - progress)})`;
      ctx.lineWidth = 6;
      ctx.stroke();
      ctx.restore();

      // Add small particles along the path
      points.forEach((point, i) => {
        if (Math.random() > 0.7) {
          ctx.beginPath();
          ctx.arc(point.x, point.y, Math.random() * 1.5, 0, Math.PI * 2);
          ctx.fillStyle = `rgba(100, 255, 218, ${Math.random() * 0.5})`;
          ctx.fill();
        }
      });
    };

    // Update the animate function
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      // Update rotation and communications
      const speedDiff = animationRef.current.targetSpeed - animationRef.current.speed;
      animationRef.current.speed += speedDiff * 0.02;
      animationRef.current.angle += animationRef.current.speed;
      updateCommunications();

      // Draw active communications
      animationRef.current.activeConnections.forEach(connection => {
        connection.progress += 0.02;
        drawCommunication(connection.dot1, connection.dot2, connection.progress);
      });

      // Draw dots
      dotsRef.current.forEach(dot => {
        const x = centerX + Math.cos(dot.angle + animationRef.current.angle) * dot.radius;
        const y = centerY + Math.sin(dot.angle + animationRef.current.angle) * dot.radius;
        
        const pulse = Math.sin(Date.now() * 0.002 + dot.pulseOffset);
        const size = dot.size * (1 + pulse * 0.3 + (dot.communicating ? 0.5 : 0));

        // Enhanced glow for communicating dots
        if (dot.communicating) {
          const glowGradient = ctx.createRadialGradient(x, y, 0, x, y, size * 6);
          glowGradient.addColorStop(0, 'rgba(100, 255, 218, 0.3)');
          glowGradient.addColorStop(0.5, 'rgba(100, 255, 218, 0.1)');
          glowGradient.addColorStop(1, 'transparent');
          ctx.fillStyle = glowGradient;
          ctx.beginPath();
          ctx.arc(x, y, size * 6, 0, Math.PI * 2);
          ctx.fill();
        }

        // Draw dot core
        ctx.beginPath();
        ctx.arc(x, y, size, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(100, 255, 218, ${0.8 + pulse * 0.2 + (dot.communicating ? 0.2 : 0)})`;
        ctx.fill();
      });

      animationFrame = requestAnimationFrame(animate);
    };

    // Start animation and state changes
    animate();
    changeState();

    return () => {
      cancelAnimationFrame(animationFrame);
      clearTimeout(animationRef.current.stateTimeout);
    };
  }, []);

  return (
    <canvas 
      ref={canvasRef}
      className="circle-canvas"
      width={400}
      height={400}
    />
  );
};

const Hero = ({ handleNavClick }) => {
  const canvasRef = useRef(null);
  const nodesRef = useRef([]);
  const rafRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let width = window.innerWidth;
    let height = window.innerHeight;

    // Don't initialize animation if on mobile
    if (width <= 768) {
        return;
    }
    
    const initCanvas = () => {
      canvas.width = width;
      canvas.height = height;
      nodesRef.current = [];

      // Create nodes in a grid pattern for more structure
      const gridSize = 180;
      const cols = Math.ceil(width / gridSize);
      const rows = Math.ceil(height / gridSize);

      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          const x = j * gridSize + (Math.random() - 0.5) * 60;
          const y = i * gridSize + (Math.random() - 0.5) * 60;
          nodesRef.current.push({
            x,
            y,
            baseX: x,
            baseY: y,
            radius: 2,
            energy: 0.3 + Math.random() * 0.2,
            connections: [],
            phase: Math.random() * Math.PI * 2,
            speed: 0.05 + Math.random() * 0.05
          });
        }
      }

      // Create connections based on proximity
      nodesRef.current.forEach((node, i) => {
        const distances = nodesRef.current
          .map((otherNode, index) => ({
            distance: Math.hypot(node.x - otherNode.x, node.y - otherNode.y),
            index
          }))
          .filter(({ distance, index }) => 
            distance > 0 && 
            distance < gridSize * 1.5 && 
            index !== i
          )
          .sort((a, b) => a.distance - b.distance)
          .slice(0, 3);
        
        node.connections = distances.map(d => d.index);
      });
    };

    const drawConnection = (node1, node2, energy) => {
      const dx = node2.x - node1.x;
      const dy = node2.y - node1.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const progress = (Math.sin(Date.now() * 0.001 + node1.phase) + 1) / 2;
      
      // Draw main line
      ctx.beginPath();
      ctx.moveTo(node1.x, node1.y);
      ctx.lineTo(node2.x, node2.y);
      ctx.strokeStyle = `rgba(0, 188, 212, ${energy * 0.15})`;
      ctx.lineWidth = 1;
      ctx.stroke();

      // Draw moving data point
      const x = node1.x + dx * progress;
      const y = node1.y + dy * progress;
      ctx.beginPath();
      ctx.arc(x, y, 1.5, 0, Math.PI * 2);
      ctx.fillStyle = `rgba(0, 188, 212, ${energy * 0.8})`;
      ctx.fill();
    };

    const animate = (time) => {
      ctx.clearRect(0, 0, width, height);

      // Draw subtle grid background
      ctx.strokeStyle = 'rgba(0, 188, 212, 0.05)';
      ctx.lineWidth = 1;
      for (let i = 0; i < width; i += 50) {
        ctx.beginPath();
        ctx.moveTo(i, 0);
        ctx.lineTo(i, height);
        ctx.stroke();
      }
      for (let i = 0; i < height; i += 50) {
        ctx.beginPath();
        ctx.moveTo(0, i);
        ctx.lineTo(width, i);
        ctx.stroke();
      }

      nodesRef.current.forEach((node, i) => {
        // Subtle floating movement
        const floatX = Math.sin(time * 0.0001 * node.speed + node.phase) * 15;
        const floatY = Math.cos(time * 0.0001 * node.speed + node.phase) * 15;
        
        node.x = node.baseX + floatX;
        node.y = node.baseY + floatY;

        // Draw connections with data flow
        node.connections.forEach(connectionIndex => {
          const connectedNode = nodesRef.current[connectionIndex];
          const energy = (Math.sin(time * 0.0005 + node.phase) * 0.5 + 0.5);
          drawConnection(node, connectedNode, energy);
        });

        // Draw node with pulse effect
        const pulse = Math.sin(time * 0.001 + node.phase) * 0.5 + 0.5;
        const radius = node.radius * (1 + pulse * 0.3);
        
        // Node glow
        const gradient = ctx.createRadialGradient(node.x, node.y, 0, node.x, node.y, radius * 8);
        gradient.addColorStop(0, `rgba(0, 188, 212, ${0.1 * node.energy})`);
        gradient.addColorStop(1, 'transparent');
        ctx.fillStyle = gradient;
        ctx.beginPath();
        ctx.arc(node.x, node.y, radius * 8, 0, Math.PI * 2);
        ctx.fill();

        // Node core
        ctx.beginPath();
        ctx.arc(node.x, node.y, radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(0, 188, 212, ${node.energy})`;
        ctx.fill();
      });

      rafRef.current = requestAnimationFrame(animate);
    };

    const handleResize = () => {
      width = window.innerWidth;
      height = window.innerHeight;
      initCanvas();
    };

    initCanvas();
    animate(0);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(rafRef.current);
    };
  }, []);

  return (
    <div className="hero-container">
      <canvas ref={canvasRef} className="neural-canvas" />
      <div className="hero-content-wrapper">
        <div className="hero-content">
          <div className="hero-text-container">
            <div className="hero-badge">
              <span className="badge-pulse"></span>
              <span className="badge-text">Your Gateway to AI</span>
            </div>
            
            <h1 className="hero-title">
              <div className="title-line">
                <span className="highlight">Empowering</span>
              </div>
              <div className="title-line">
                <span className="highlight">Students to Grasp</span>
              </div>
              <div className="title-line">
                <span className="gradient-text">Global Industry</span>
              </div>
              <div className="title-line">
                <span className="gradient-text">Transformations</span>
              </div>
            </h1>
            
            <p className="hero-description">
              CBS AI is your gateway to the world of AI. We help CBS students understand how AI is changing industries globally. Join us to learn more about AI, discover its potential, and stay ahead in the ever-changing business world.
            </p>

            <div className="hero-cta">
              <button 
                  className="cta-button primary" 
                  onClick={(e) => handleNavClick(e, 'events')}
              >
                  <span className="button-content">
                      <svg className="button-icon" viewBox="0 0 24 24">
                          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
                      </svg>
                      Explore Events
                      <span className="button-arrow">→</span>
                  </span>
              </button>
              <button 
                  className="cta-button" 
                  onClick={(e) => handleNavClick(e, 'newsletter')}
              >
                  <span className="button-content">
                      <svg className="button-icon" viewBox="0 0 24 24">
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/>
                      </svg>
                      Join Newsletter
                      <span className="button-arrow">→</span>
                  </span>
              </button>
            </div>
          </div>

          <div className="hero-visual-container">
            <AnimatedCircle />
            <div className="hero-stats">
              <a 
                href="https://www.instagram.com/cbs_ai/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="stat-item"
              >
                <div className="stat-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                  </svg>
                </div>
                <div className="stat-label">Instagram</div>
              </a>
              <a 
                href="https://www.linkedin.com/company/100301567/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="stat-item"
              >
                <div className="stat-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                  </svg>
                </div>
                <div className="stat-label">LinkedIn</div>
              </a>
              <a 
                href="https://open.spotify.com/show/1Jkc1Niu1tknJqLrKjWVIp?si=4c9617193d3f49ab" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="stat-item"
              >
                <div className="stat-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
                  </svg>
                </div>
                <div className="stat-label">Podcast</div>
              </a>
              <a 
                href="https://www.facebook.com/profile.php?id=61554921964142" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="stat-item"
              >
                <div className="stat-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M22.675 0h-21.35C.6 0 0 .6 0 1.325v21.35C0 23.4.6 24 1.325 24h21.35C23.4 24 24 23.4 24 22.675V1.325C24 .6 23.4 0 22.675 0zM12 24V12h-3v-4h3V6c0-3.1 1.9-4.8 4.7-4.8 1.3 0 2.5.1 2.8.2v3.2h-1.9c-1.5 0-1.8.7-1.8 1.7v2.1h4l-.5 4h-3.5V24h-4z"/>
                  </svg>
                </div>
                <div className="stat-label">Facebook</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
