import React from 'react';
import './styles/mission.css';

const Mission = () => {
  return (
    <section id="mission" className="mission-section">
      <div className="mission-container">
        <div className="mission-content">
          <h2 className="mission-title">
            <span className="mission-number">002.</span>
            Our Mission
          </h2>
          
          <div className="mission-grid">
            <div className="mission-text">
              <p className="mission-description">
                CBS AI is a students organization at CBS. The aim of CBS AI is to utilize and explore the benefits of AI through seminars, guest speakers, workshops, and networking events. At CBS AI, we strive to provide our members with the best possible resources to help them succeed in the usage of AI in their professional careers and academic journey in an ethical manner.
              </p>
              <div className="mission-stats">
                <div className="stat">
                  <span className="stat-highlight">20.000+</span>
                  <span className="stat-label">Students Exposure at CBS</span>
                </div>
                <div className="stat">
                  <span className="stat-highlight">15.000+</span>
                  <span className="stat-label">Monthly Social Media Exposures</span>
                </div>
                <div className="stat">
                  <span className="stat-highlight">20+</span>
                  <span className="stat-label">Dedicated Team Members</span>
                </div>
                <div className="stat">
                  <span className="stat-highlight">100%</span>
                  <span className="stat-label">Event Sell-out Rate</span>
                </div>
              </div>
            </div>
            
            <div className="mission-visual">
              <div className="visual-grid">
                <div className="grid-item">
                  <div className="grid-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M15 11V5l-3-3-3 3v2H3v14h18V11h-6zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z"/>
                    </svg>
                  </div>
                  <h3>Connecting students with AI business</h3>
                </div>
                <div className="grid-item">
                  <div className="grid-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M12 3L1 9l4 2.18v6L12 21l7-3.82v-6l2-1.09V17h2V9L12 3zm6.82 6L12 12.72 5.18 9 12 5.28 18.82 9zM17 15.99l-5 2.73-5-2.73v-3.72L12 15l5-2.73v3.72z"/>
                    </svg>
                  </div>
                  <h3>Expanding AI skill sets</h3>
                </div>
                <div className="grid-item">
                  <div className="grid-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
                    </svg>
                  </div>
                  <h3>Representing AI enthusiasm at CBS</h3>
                </div>
                <div className="grid-item">
                  <div className="grid-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/>
                    </svg>
                  </div>
                  <h3>Gateway to CBS students</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
