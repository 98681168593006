import React, { useState } from 'react';
import './styles/newsletter.css';
import { db } from '../firebase';  // Sørg for at importere din firebase config
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const Newsletter = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('idle'); // 'idle', 'loading', 'success', 'error'

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            setStatus('loading');
            
            // Tilføj email til newsletter collection
            await addDoc(collection(db, 'newsletter'), {
                email: email,
                timestamp: serverTimestamp(),
                source: window.location.hostname
            });

            setStatus('success');
            setEmail('');
            
            // Reset success message efter 3 sekunder
            setTimeout(() => {
                setStatus('idle');
            }, 3000);

        } catch (error) {
            console.error('Error adding email to newsletter:', error);
            setStatus('error');
            
            // Reset error message efter 3 sekunder
            setTimeout(() => {
                setStatus('idle');
            }, 3000);
        }
    };

    const getButtonText = () => {
        switch (status) {
            case 'loading':
                return 'Subscribing...';
            case 'success':
                return 'Subscribed!';
            case 'error':
                return 'Try Again';
            default:
                return 'Subscribe Now';
        }
    };

    return (
        <section id="newsletter" className="newsletter-section">
            <div className="newsletter-container">
                <div className="newsletter-content">
                    <h2 className="newsletter-title">
                        <span className="newsletter-number">006.</span>
                        Stay Connected
                    </h2>
                    
                    <div className="newsletter-grid">
                        <div className="newsletter-text">
                            <p className="newsletter-description">
                                Join our community to receive exclusive updates about upcoming events and exclusive insights from industry experts.
                            </p>
                        </div>

                        <div className="newsletter-form-container">
                            <div className="form-header">
                                <div className="form-badge">
                                    <span className="badge-pulse"></span>
                                    <span className="badge-text">Newsletter</span>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} className="newsletter-form">
                                <div className="form-group">
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter your email address"
                                        required
                                        className="input-field"
                                        disabled={status === 'loading'}
                                    />
                                </div>
                                <button 
                                    type="submit" 
                                    className={`cta-button primary ${status}`}
                                    disabled={status === 'loading'}
                                >
                                    <span className="button-content">
                                        {getButtonText()}
                                        {status === 'idle' && <span className="button-arrow">→</span>}
                                    </span>
                                </button>
                            </form>
                            {status === 'error' && (
                                <div className="form-error">
                                    Something went wrong. Please try again.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Newsletter;
